import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import Image from '../components/image'

const IndexPage = () => (
  <Layout>
    <h1>Hi people</h1>
    <p>This site is currently under construction.</p>
    <p>Please come back a bit later.</p>
  </Layout>
)

export default IndexPage
